@import "../../assets/stylesheets/variables";

.checkboxContainer {
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 12px 0;
}

.checkboxLabel {
  padding-left: 15px;
  color: $gray_brown;
  cursor: pointer;
}

input.checkbox {
  box-shadow: none;
}
