@font-face {
  font-family: "PlutoSansDPD";
  font-weight: 100;
  src: local("PlutoSansDPDLight"), url("assets/fonts/PlutoSansDPDLight.otf");
}

@font-face {
  font-family: "PlutoSansDPD";
  font-weight: 100;
  font-stretch: condensed;
  src: local("PlutoSansDPDExtraLight"),
    url("assets/fonts/PlutoSansDPDExtraLight.otf");
}

@font-face {
  font-family: "PlutoSansDPD";
  font-stretch: condensed;
  src: local("PlutoSansDPDThin"), url("assets/fonts/PlutoSansDPDThin.otf");
}

@font-face {
  font-family: "PlutoSansDPD";
  font-weight: bold;
  src: local("PlutoSansBold"), url("assets/fonts/PlutoSansBold.otf");
}

@font-face {
  font-family: "PlutoSansDPD";
  font-weight: normal;
  src: local("PlutoSansDPDRegular"), url("assets/fonts/PlutoSansDPDRegular.otf");
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/RobotoRegular.woff");
  font-weight: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/RobotoLight.woff");
  font-weight: 100;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/RobotoBold.woff");
  font-weight: bold;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/RobotoMedium.woff");
  font-weight: 500;
}
