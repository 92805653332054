$black: black;
$white: white;
$gray_brown: #4d4d4d;
$red: #a90034;
$bright_red: #df1946;
$selected_red: #dc0032;
$light_gray: #e6e7e7;
$gray: #cbc4bf;
$blue_1: #3e8af0;
$blue_2: #84bbf3;
$blue_3: #378de5;
$light_gray_2: #dedede;
$light_gray_3: #a6a6a6;
$configure-printer: #424143;
$rolling_stone: #808285;
$silver_rust: #cac4be;
$light_black: #000000bf;
$concrete: #f3f3f3;
$silver: #cbcbcb;
$brown: #414042;
$brown_2: #777;
$dusty_gray: #999999;
$ship_gray: #424143;
$dark_gray: #333333;
$disabled_color: #dddddd;

$ui-primary-light: $selected_red;
$ui-primary-dark: $red;

$ui-local-light: #009adf;
$ui-local-dark: #0a61a2;

:export {
  white: $white;
  black: $black;
  red: $red;
  gray_brown: $gray_brown;
  dark_gray: $dark_gray;
  light_gray: $light_gray;
  gray: $gray;
  brown: $brown;
  brown_2: $brown_2;
  blue_1: $blue_1;
  blue_2: $blue_2;
  blue_3: $blue_3;
  light_gray_2: $light_gray_2;
  bright_red: $bright_red;
  selected_red: $selected_red;
  rolling_stone: $rolling_stone;
  silver_rust: $silver_rust;
  light_black: $light_black;
  concrete: $concrete;
  silver: $silver;
  dusty_gray: $dusty_gray;
  ship_gray: $ship_gray;
  uiLocalLight: $ui-local-light;
  uiLocalDark: $ui-local-dark;
  uiPrimaryLight: $ui-primary-light;
  uiPrimaryDark: $ui-primary-dark;
}
