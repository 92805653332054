div.row {
  display: flex;
  justify-content: space-between;

  div.label {
    width: 70px;
  }

  div.field {
    width: 170px;
  }

  .container {
    display: block;
    position: relative;
  }
}

.error {
  position: absolute;
}
