@import "../../assets/stylesheets/variables";

.subTitleContainer {
  display: flex;
  flex: 1;
  padding: 1.2rem 0;

  .subTitle {
    padding: 5px;
    color: $white;
    background-color: $rolling_stone;
    width: 100%;
    font-size: 0.75rem;
    font-weight: bold;
  }
}
