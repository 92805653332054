@import "../../assets/stylesheets/variables";

div.content {
  width: 100%;
  padding: 20px 20px 12px;
  background-color: $light_gray;
  .column {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}
