@import "../../assets/stylesheets/variables";
.container {
  width: 100%;
  text-align: right;
}

.mandatory {
  color: $bright_red;
  margin-right: 3px;
}

.label {
  color: $gray_brown;
}
