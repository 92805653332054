@import "../../../assets/stylesheets/variables";

.account_container {
    display: flex;
    justify-content: space-between;
    font-family: Arial, Sans-Serif, serif;
    font-size: 1.2em;
    font-weight: bold;
    color: $ship_gray;
    padding: 0 5px 8px;
  
    .flex {
      display: flex;
    }
  }