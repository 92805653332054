@import "../../assets/stylesheets/variables";

.fieldContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  margin-bottom: 7px;

  .fieldLabel {
    color: $gray_brown;
    padding: 3px 0;
  }
  .field {
    input {
      width: 100%;
      height: 1.375rem;
      padding: 0 0 0 5px;
      &:disabled {
        background-color: $disabled_color;
      }
    }

    .invalid {
      border: 1px solid $red;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type="number"] {
      -moz-appearance: textfield; /* Firefox */
    }
    textarea {
      padding: 0.5rem;
      width: 100%;
      resize: none;
    }
  }

  div.field {
    width: 13rem;
  }

  span.required {
    color: $bright_red;
    padding-right: 5px;
  }

  div.error {
    color: $red;
    padding-top: 5px;
    padding-left: 5px;
  }
}
