@import "../../../assets/stylesheets/variables";

div.container {
  display: flex;
  flex-direction: column;
  width: 8.375rem;
  font-size: 0.6875rem;
  padding: 0 1.25rem 0 0.625rem;
}

a:visited {
  color: $ship_gray;
}

.menuItem {
  padding: 5px 0;
  text-decoration: none;
  border-bottom: 1px solid $light_gray_2;
  color: $ship_gray;
  line-height: 1.6;
}

a.activeLink {
  color: $bright_red;
  font-weight: 600;
}
