.scanningContainer {
  padding: 10px 0;
}

.labelWrapper {
  padding: 20px 0 0;
  label {
    display: inline-flex;
    text-align: center;
  }
  &:first-child {
    padding: 0;
  }
}
