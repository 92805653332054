@import "../../assets/stylesheets/variables";

.container {
  width: 100%;
}

.checkboxContainer {
  flex-direction: row;
  display: flex;
  align-items: center;
  margin: 7px 0;
}
.checkboxLabel {
  padding-left: 15px;
}
