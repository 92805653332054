@import "../../assets/stylesheets/variables";

.container {
  width: 100%;

  .bottomSection {
    background-color: $gray;
    display: flex;
    justify-content: flex-end;
    padding: 10px;

    button {
      box-shadow: 0px 0px 2px 2px $blue_1;
      background-color: $blue_1;
      border-radius: 3px;
      border: 1px solid $blue_2;
      display: inline-block;
      cursor: pointer;
      color: $white;
      font-size: 15px;
      font-weight: bold;
      padding: 6px 24px;
      text-decoration: none;
      text-shadow: 0px 1px 0px $blue_1;
    }

    button:hover {
      background-color: $blue_3;
    }
  }
}

div.field {
  width: 25%;
  min-width: 150px;
}

.fieldContainer {
  margin: 0 0 1rem 0 !important;
}
