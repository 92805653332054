@import "../../assets/stylesheets/variables";

div.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0 0 15px 25px;
  max-width: 940px;
}

.main {
  display: flex;
  flex-direction: column;
  width: 50rem;
  font-size: 0.6875rem;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 25px;
}

.main_content {
  display: flex;
  width: 100%;
}

p.copyright {
  line-height: 1.4em;
  margin-top: .5em;
  margin-bottom: .5em;
  text-align: center;
  color: $rolling_stone;
  margin: 10px 0 0 0;
  font-size: 62.5%;
}