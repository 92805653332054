@import "~normalize.css/normalize.css";
@import "assets/stylesheets/variables";
@import "assets/stylesheets/fonts";

body {
  margin: 0 auto;
  font-family: $arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
*:-moz-focusring,
*:focus {
  outline: none !important;
}

section {
  max-height: 100%; // Disable accessibility text resize in Chrome
}

.hidden {
  display: none;
}

input::-ms-clear,
input::-ms-reveal {
  display: none; // Disable the little x on the input to clear content and the eye on password fields in Edge
}

select::-ms-expand {
  display: none;
}

select,
input {
  border: 0;
  box-shadow: inset 0 1px 1px $silver;
  color: $light_black;
}

input:focus,
textarea:focus {
  box-shadow: 0px 0px 5px $dusty_gray;
  outline: none;
  background-color: $concrete;
}

select:hover {
  background-color: $concrete;
}

textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  border: 0;
}
