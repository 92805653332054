@import "../../../assets/stylesheets/variables";

.container {
  display: flex;
  padding-bottom: 7px;
  padding-right: 2px;

  .top_container {
    display: flex;
    flex-direction: column;
    width: 51rem;
  }

  .logo {
    display: flex;
    padding-top: 18px;
    height: 55px;
  }

  .utilities {
    display: flex;
    margin: 8px 0 8px 18px;
    width: 100%;
    font-family: Arial, Sans-Serif, serif;
    font-size: 10px;
    justify-content: flex-end;

    span {
      margin: 0 5px;
    }

    a {
      color: $brown;
      text-decoration: none;
    }
    a:visited {
      color: $brown;
    }
  }
}
