@import "../../assets/stylesheets/variables";

.bottomSection {
  background-color: $silver_rust;
  display: flex;
  justify-content: flex-end;
  padding: 10px;

  .bottonWraper {
    display: flex;
    align-items: center;
  }

  button {
    background-color: $blue_1;
    display: inline-block;
    cursor: pointer;
    color: $white;
    font-weight: bold;
    text-decoration: none;
    padding: 0.375rem;
    height: 1.5rem;
    font-size: 0.6875rem;
    letter-spacing: 0.8px;
    border: none;

    &:disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    &:focus {
      outline: none;
    }
  }

  button:hover {
    box-shadow: 0px 0px 2px 2px $blue_1;
    background-color: $blue_3;
    text-shadow: 0px 1px 0px $blue_1;
  }
}
