@import "../../../assets/stylesheets/variables";

.infoContainer {
  width: 500px;
  margin: auto;
  padding-bottom: 10px;
  font-size: 12px;
  font-style: italic;
  color: $dark_gray;
  font-weight: 200;
  line-height: 1.5;
}

.icon {
  display: inline-block;
  margin-right: 5px;
  svg {
    width: 11px;
    height: 11px;
  }
}
