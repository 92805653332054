@import "../../../assets/stylesheets/variables";

.primary_header {
  background: $light_gray;
  width: 100%;
  margin-left: 18px;
}

.link {
  color: $brown;
  text-decoration: none;

  display: inline-block;
  font-size: 13px;
  line-height: 23.4px;
  font-family: Arial, Sans-Serif, serif;
  padding: 0 18px;
  &:hover,
  &.active {
    color: $white;
  }
}
