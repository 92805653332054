@import "../../assets/stylesheets/variables";

.container {
  width: 100%;
}

div.printToTerminal {
  padding-bottom: 0;
}

.button {
  height: 24px;
  width: 131px;
  margin-left: 5px;
  border: none;
  background-color: $configure-printer;
  color: $white;
  cursor: pointer;
}

.printer {
  width: 75%;
  display: flex;
  flex-direction: row;
}

.printerNameWrapper {
  justify-content: flex-start;
  display: flex;
}

.printerTipSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  padding: 0.75rem 0;
}

.tipImages {
  display: flex;
  justify-content: space-between;
  width: 33%;
}

.dpdAssetNumber {
  box-shadow: 2px 2px 2px $light_gray_3;
}

.tipText {
  width: 62%;
  color: $gray_brown;
  font-size: 0.6875rem;
  line-height: 1.5;
}

.labelSettings {
  flex-direction: row;
}

.laserPrinterSection {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
}

label.radioLabel {
  margin-left: 14px;
  input {
    margin-right: 7px;
  }
}
