@import "../../assets/stylesheets/variables";

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    div > div > input {
      width: 13rem;
      height: 1.375rem;
      padding: 0 0 0 5px;
    }
  }

  .label {
    color: $gray_brown;
    cursor: pointer;
  }

